.mis-vis{
    width: 90%;
    display: grid;
    justify-content: center;
    justify-items: center;
    
    margin: 7% auto;
    padding: 2%;
    text-align: center;
}



.mis-heading{
    margin-bottom: 2.5rem;
    
}


.mission-heading{
    font-size: 2rem;
    font-weight: 600;
    color: #285fac;
    text-align: center;
}

.mission-subheading{
    font-size: 1.2rem;
}

.mission-text{
    font-size: 1rem;
    text-align: center;
}

@media screen and (min-width: 768px) {
        
    .mission-heading{
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 2%;
    }
    
    .mission-subheading{
        font-size: 1.8rem;
    }
    
    .mission-text{
        font-size: 1.8rem;
    }

    .mis-heading{
        margin-bottom: 2rem;
    }
}