
.myFont{
    font-size: 1.4rem;
    margin-left: 5%;
}
.brand-img{
    cursor: pointer;
}


.con-icon{
    color: #fff;
    margin:0 10px;
}


.myFont{
    color: #000;
    font-weight: 500;
}

/*656866*/
.reg-btn{
    background-color: #285fac;
    color: #fff;
}

.myFont:active{
    color: #000;
}

.myFont:hover{
    color: #285fac;
}

#basic-nav-dropdown{
    color: #000;
}

#basic-nav-dropdown:hover{
    color:#285fac;
}

.reg-btn{
    width: 50%;
}

.reg-btn:hover{
    
    background-color: #fff;
    color: #285fac;
}

.boost-logo{
    display: inline-block;
}

@media  screen and (min-width:768px) {
    /* .contact-banner{
        display: none;
        background-color: #285fac;
        height: 15%;
   */

   .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
   }
    div.contact-banner{
        display: none;
    }

    .boost-logo{
        display: inline-block;
    }

    .navv{
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media  screen and (min-width:1024px) {
    div.contact-banner{
        display: block;
    }

    .reg-btn{
        width: 20%;
    }
    .boost-logo{
        display: inline-block;
    }
    .navv{
        width: 100%;
    }
}