.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 999;
}

.popup {
    margin: 5% auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 40%;
    position: relative;
    transition: all 1s ease-in-out;
}

.popup img {
    width: 100%;
    height: 100%;
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.popup .close:hover {
    cursor: pointer;
    color: #000;
}

.popup .content {
    max-height: 30%;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .popup {
        width: 70%;
        margin: 40% auto;
    }

    .popup .content {
        max-height: 100%;
        overflow: auto;
    }
}