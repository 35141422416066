/* Base styles for the page (mobile-first) */
body {
   
    text-align: justify; /* Justify text by default */
  }
  
  /* General styling for headings */
  h2 {
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #285fac; /* Heading color */
    line-height: 1.6; /* Increased line-height for better readability */
    text-transform: uppercase; /* Uppercase text for emphasis */
    letter-spacing: 1px; /* Add letter spacing for more impact */
    text-align: justify; /* Justify headings */
    padding-left: 10px;
    padding-right: 10px;
    border-left: 4px solid #285fac; /* Left border for emphasis */
    padding-left: 15px; /* Padding inside heading */
    margin-left: 10px; /* Margin for a clean start */
  }
  
  /* Paragraph styling */
  p {
    font-size: 1rem;
    margin-bottom: 25px;
    color: #6a6a6a; /* Dark grey for text */
    text-align: justify; /* Justify paragraph text */
    line-height: 1.8;
    padding: 0 20px; /* Add padding for better text flow */
    
    
  }
  
  /* Mobile-first styles (default for screens smaller than 768px) */
  @media (max-width: 768px) {
    h2 {
      font-size: 1.3rem; /* Smaller heading size for mobile */
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 20px;
    }
  
    p {
      font-size: 0.95rem; /* Smaller font size for paragraphs */
      padding: 0 10px; /* Add padding to paragraphs */
    }
  }
  
  /* Styles for tablets and small laptops (768px to 1024px) */
  @media (min-width: 768px) and (max-width: 1024px) {
    h2 {
      font-size: 1.4rem; /* Slightly larger heading size for tablets */
      margin-top: 30px; /* More space above the heading */
    }
  
    p {
      font-size: 1.1rem; /* Slightly larger paragraph text */
    }
  }
  
  /* Styles for larger screens (1024px and above) */
  @media (min-width: 1024px) {
    h2 {
      font-size: 1.6rem; /* Larger headings for desktops */
      margin-top: 40px; /* More space for large screens */
    }
  
    p {
      font-size: 1.2rem; /* Larger paragraphs for readability */
      padding: 0 20px; /* Add more padding for larger screens */
    }
  }
  