/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,700;1,700&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;1,400;1,700&display=swap'); */

  /* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;1,400;1,700&family=Montserrat&display=swap'); */
  
  /* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,700;1,400;1,700&family=Montserrat&display=swap'); */


* {
  /* overflow-x: hidden; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./assets/bg.jpg"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  /* margin-top: 10%; */
  margin-bottom: 2rem;
}

.text-purple {
  color: #4b0082;
}

.myBtn {
  background-color: #285fac;
  color: #fff;
  border-radius: 20px;
}


.myBtn:hover {
  background-color: #fff;
  color: #285fac;
  font-weight: 600;
}

