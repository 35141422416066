.directors-msg-section{
    width:90%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
}
.heading-dir-msg-section{
    margin: 4rem 0;
}

.heading-dir-msg-section h2{
    font-size: 1.4rem;
    color: #285fac;
    font-weight: 600;
    text-align: center;
    
}

.bansal-msg{
    margin-bottom: 8%;
}
.bansal-msg img{
    max-width: 300px;
    float: left;
    border-radius: 20px;
    margin-right: 2rem;
}

.about-us-text-main {
    margin: 2rem 0;
    
}

.about-us-text-main h2 {
    /* margin: 3rem 0; */
    text-align: center;
    color: #285fac;
    font-size: 1.8rem;
}

.about-us-text-main h4 {
    margin: 2rem 0;
    text-align: center;
}

.about-us-text-main p {
    margin: 2rem 0;
    text-align: justify;
}

@media screen and (min-width: 768px) {
    .directors-msg-section{
        width: 80%;
        margin: 2rem auto;
        padding: 2rem;
        display: flex;
        flex-direction: column;
    }

    .bansal-msg img{
        max-width: 480px;
        float: left;
        border-radius: 20px;
        margin-right: 2rem;
    }

    .heading-dir-msg-section h2{
        font-size: 2.5rem;
        color: #285fac;
        font-weight: 600;
        text-align: center;
        
    }

    .about-us-text-main h2 {
        margin:  0;
        text-align: start;
        color: #285fac;
        font-size: 1.8rem;
    }
    
    .about-us-text-main h4 {
        margin: 2rem 0;
        text-align: start;
    }
    
    .about-us-text-main p {
        margin: 2rem 0;
        text-align: justify;
    }
}

@media screen and (min-width: 1024px) {
    .directors-msg-section{
        width: 70%;
        margin: 2rem auto;
        padding: 2rem;
        display: flex;
        flex-direction: column;
    }
}