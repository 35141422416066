.header {
    width: 100%;
    height: 30vh;
}

.swiper {
    width: 100%;
    height: 100%;
}

img.img {
    width: 100%;
    height: auto;
}

.swiper-button-next {
    color: #fff;
}

.swiper-button-prev {
    color: #fff;
}

.img-slider {
    width: 100%; /* take up full width of container */
    object-fit: contain; /* don't compress the image */
    height: auto; /* adjust height automatically */
}

@media screen and (min-width: 768px) {
    .header {
        width: 100vw;
        padding: 0 0rem;
        height: 60vh;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .img-slider {
        /* width: 100%;
        /* height: fit-content; */
        /*
        object-fit: fill;
        width: min-content; */
        max-width: 100%;
        height: 100%;
        width: 100%;
    }
}

@media screen and (min-width: 1024px) {
    .header {
        width: 100vw;
        padding: 0 0rem;
        height: 60vh;
    }

    .swiper {
        margin-top: 5%;
        width: 100%;
        height: 100%;
    }

    .img-slider{
        width: 100%;
        height: 100%;
        /* height: fit-content; */
        /* width: min-content;} */
    }

    .img-slider {
        /* width: 100%;
        /* height: fit-content; */
        /*
        object-fit: fill;
        width: min-content; */
       
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

}