.about-us-main {
    width: 100%;
    margin: 1rem auto;
    padding: 1rem 1rem;
    overflow-x: hidden;
}

.about-heading h2 {
    margin-top: 1rem;
    text-align: center;
    font-weight: 700;
    color: #000;
}

.about-heading p {
    margin: 1rem 0;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    color: #808080;
}

.about-text-box {
    margin: 2rem 0;
    width: 100%;
    /* min-height: 60vh; */
    /* display: flex;
    flex-direction: column; */
}



.about-text-box img {
    max-width: 320px;
    float: left;
    border-radius: 20px;

}

.about-us-text-main {
    margin: 2rem 0;
}

.about-us-text-main h2 {
    margin: 2rem 0;
}

.about-us-text-main h4 {
    margin: 2rem 0;
}

.why-sagar{
    background: rgba(255, 255, 255, 0.8);
    padding: 0rem;
    border-radius: 20px;
    padding: 1rem;
}

.why-sagar h2{
    font-size: 2rem;
    margin: 2rem 0;
    text-align: center;
    color: #FFC000;
}

.why-sagar p{
    font-size: 1.2rem;
    text-align: justify;
}

li{
    margin-bottom: 1rem;
}

.why-sagar-box{
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 3rem;
    /* padding: 2rem; */
}
@media screen and (min-width: 768px) {

    .about-us-main {
        width: 90%;
        margin: 1rem auto;
        padding: 1rem 2.5rem;
        overflow-x: hidden;
    }

    .about-text-box {
        margin: 5rem auto;
        width: 100%;
        
    }

    .why-sagar{
        width: 100%;
        margin: 2rem auto;
        padding: 2rem;
        box-shadow: 10px 10px 10px rgba(0,0,0,0.9);
    }

    .why-sagar h2{
        font-size: 2rem;
        margin: 5rem 0;
        text-align: center;
        color: rgb(40, 95, 172);
    }
    
    .why-sagar p{
        font-size: 1.3rem;
        text-align: center;
        color: rgb(40, 122, 172);
    }
    ol{
        width: 100%;
        padding: 2rem;
        margin: 0  auto;
    }
    li{
        font-size: 1.3rem;
        color: #000;
        
    }

    .why-sagar-box{
        grid-template-rows: unset;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
        
    }
/*
    .about-us-text-main {
        margin: 0;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;

    }

    .about-us-text-main h2 {
        margin: 0;
    }

    .about-us-text-main p {
        text-align: justify;
        font-size: 1.2rem;
    } */


    .about-text-box img {
        max-width: 480px;
        /* height: 50%; */
        align-self: flex-start;
        float: left;
        margin-right: 10px; 
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 1024px) {
    .about-text-box {
        margin: 5rem auto;
        width: 70%;
    }
}