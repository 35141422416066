.header-img-block{
    background-image: url('../../assets/bgg.jpg');
    height: 25dvh;
    background-size: cover;

}

.transparent{
    width: 100%;
    height: 100%;
    /* background-color: #fff; */
    /* opacity: 0.31; */
    display: grid;
    align-items: center;
    justify-items: center;
}

.transparent p{
    font-size: 1.4rem;
    font-weight: 900;
    color: #fff;
}

@media screen and (min-width: 768px) {


    .transparent p{
        font-size: 3.4rem;
        font-weight: 700;
    }

}