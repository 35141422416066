.footer {
    background-color: #285fac;
    padding: 0.5rem 0.5rem;
    margin-top: 2rem;
    /* height: 100vh; */
}

.footer-logo {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    margin: 0 10px;
    margin-top: 10px;
    gap: 1rem;
}

.footer-logo img {
    width: 60%;
    height: 80%;
    align-self: center;
    justify-self: flex-start;
}

.footer-logo p {
    font-size: 0.8rem;
    color: #fff;
    text-align: justify;
}

.get-in-touch {
    padding: 0.5rem;
    display: grid;
    /* grid-template-rows: repeat(5, 1fr); */
}

.footer-heading p {
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.footer-heading .icons {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
}

.sicon {
    cursor: pointer;
    color: #fff;
}

.sicon:hover{
    transform: scale(1.25);
}

.iconn{
    font-size: 1.5rem;
}

.get-in-touch-box {
    width: 100%;
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    /* justify-items: center; */
    padding: 0 1rem;
}

.get-in-touch-box p {
    color: #fff;
    font-size: 1rem;
    text-align: left;
    font-weight: 500;
    margin-bottom: 1rem;
}

.icon-footer {
    color: #fff;
    font-size: 0.8rem;
    /* height: 1em; */
}

.courses-links-box {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    padding: 0.5rem;
    gap: 0.5rem;
}

.course-link {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    align-items: center;
    padding: 0 0.5rem;

}

.course-link .icon-footer {
    justify-self: flex-end;
}

.course-link span {
    color: #fff;
    font-size: 1rem;
}

.quick-links-box {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    padding: 0.5rem;
    gap: 0.5rem;
    /* margin-top: 1rem; */
}

.quick-link {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    align-items: center;
    padding: 0 0.5rem;

}

.footer-para .link-p {
    color: #fff;
}

.quick-link .icon-footer {
    justify-self: flex-end;
}

.quick-link span {
    color: #fff;
    font-size: 1rem;
}

.footer {
    margin-bottom: 0;
}

.copyright {
    text-align: center;
    color: #000;
    font-size: 1rem;
    background-color: #fff;
    width: 100%;
}

.links:hover {
    cursor: pointer;
}

.footer-para Link {
    color: #fff;
}

@media screen and (min-width: 768px) {

    .footer {
        display: grid;
        justify-items: center;
        margin-top: 10%;
    }

    .footer-info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 80vw;
        justify-items: center;

    }

    .footer-heading p {

        padding: 0 1rem;
        text-align: left;
        margin-left: 0.8rem;
        font-size: 1.8rem;
        /* display: inline-block; */
    }

    .footer-logo {
        display: grid;
        grid-template-rows: 1fr 0.5fr;
        justify-self: start;
        margin: 0 auto;
        margin-top: 10px;
        gap: 0.5rem;
        width: 80vw;

    }

    .footer-logo img {
        width: 20%;
        height: auto;
        align-self: center;
        justify-self: flex-start;
    }

    .footer-logo p {
        font-size: 1.2rem;
        color: #fff;
        text-align: justify;
        justify-self: start;
    }

    .get-in-touch {
        justify-self: center;
        padding: 0;
    }

    p.footer-para {
        font-size: 1.2rem;
    }

    span.footer-para {
        font-size: 1.2rem;
        font-weight: 500;
    }

    
}