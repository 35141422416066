.courses {
    width: 100%;
    text-align: center;
}

.main-box{
    display: grid;
    grid-template-rows: 1fr 0.1fr;
}

.course-section-heading {
    font-size: 200%;
    font-weight: 500;
    padding-bottom: 0.5rem;
    display: inline-block;
    color: #f14d5d;
    border-bottom: #f14d5d dashed;
    margin: 1.5rem 0;
    text-align: center;
}

.links-box{
    width: 80%;
    box-shadow: 5px 5px 10px #285fac;
    margin: 2rem auto;
    border-radius: 12px;
}

.link{
    color:#285fac;
}

.courses-list {
    width: 90%;
    margin: 1rem auto;
    display: grid;
    gap: 3rem;
    grid-template-rows: repeat(3, 1fr);
}

.bbox {
    padding: 1rem 2rem;
    display: grid;
    grid-template-rows: 1fr 1.5fr;
    box-shadow: 8px 10px 10px #285fac;
    gap: 1rem;
}

.bbox-img {
    width: 100%;
}



.bbox-img img {
    width: 100%;
    margin: 0 auto;
}


.bbox-course-subheading{
    color: #5e5e5e;
    font-weight: 600;
}
.read {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: end;
}

.read-more {
    color: #285fac;
    border-bottom: 2px solid #285fac;
    display: inline-block;
    justify-self: end;
    cursor: pointer;
    margin-left: auto;
}

@media screen and (min-width: 768px) {
    .courses-list {
        width: 100%;
        margin: 5% 5%;
        display: grid;
        gap: 3rem;
        grid-template-rows: repeat(3, 1fr);
    }

    
    .main-box{
        width: 100%;
        display: grid;
        grid-template-rows:unset;
        grid-template-columns: 1fr 0.5fr;
        gap: 5%;
    }
    
    .links-box{
        height: 400px;
        padding: 1rem;
    }

    .links-list{
        margin-top: 10%;
        text-align: center;
    }

    .link{
        width: 50%;
        margin: 5% auto;
        font-size: 1.3rem;
        font-weight: 600;
        cursor: pointer;
        /* display: inline-block; */
        border-bottom: 2px solid #5e5e5e;
    }

    .bbox {
        padding: 1rem 2rem;
        display: grid;
        grid-template-rows: unset;
        grid-template-columns: 1fr 1.5fr;
        box-shadow: 8px 10px 10px;
        gap: 1rem;
    }

    

    .bbox-img img {
        width: 100%;
        margin: 0 auto;
    }

    .courses-list p{
        font-size: 1.2rem;
    }
}

@media screen and (minwidth: 1024px) {
    .main-box{
        width: 80%;
        /* display: grid;
        grid-template-rows:unset;
        grid-template-columns: 1fr 0.5fr;
        gap: 5%; */
    }

    .link{
        font-size: 1.4rem;
    }
}