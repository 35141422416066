.contact-section{
    margin-top: 8%;
}

.contact-info-box {
    margin: 0.5rem;
    padding: 0.5rem;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
    background-color: #ecf4ff;
}

.link-p{
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.info-box {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    gap: 0.8rem;
    text-align: justify;
    height: auto;
    align-items: center;
}

.info {
    display: grid;
    grid-template-columns: auto;
    gap: 0.3rem;
}

.icon {
    padding: 0.5rem;
    /* background-color: aquamarine; */
    color: #fff;
    align-self: center;
}

.location-icon {
    background-color: #4b0082;

}

.call-us-icon {
    background-color: #f14d5d;
}

.email-icon {
    background-color: #ffc107;
}

.info-heading {
    font-size: 1.2rem;
    font-weight: 600;
    align-self: flex-start;
}

.wsp-icon {
    color: green;
    align-self: center;
}

p.form-heading {
    /* text-align: center; */
    display: inline-block;
    font-size: 1.2rem;
    padding: 0.5rem 0;
    text-align: center;
    color: #f14d5d;
    border-bottom: #f14d5d dashed;
    margin: 1.5rem 0;
}

.form-subheading {
    color: #000;
    font-size: 2rem;
    font-weight: 600;
    text-align: justify;
    text-transform: capitalize;
}

.form-box {
    width: 100%;
    margin: 0 auto;
    padding: 0  2rem;
    display: grid;
    align-items: stretch;
    gap: 0;
    /* justify-content: center; */
    /* justify-items: center; */
    grid-template-rows: repeat(3, 1fr);
}

.row-form {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 0;
}

/* .row-3 {
    grid-template-rows: 1fr;
} */

.row-4{
    margin-top: 1.5rem;
    justify-items: center;
    width: 100%;
}

.btn-form{
    background-color: #3B71CA;
    padding: 0.8rem;
    /* jus */
    width: 50%;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 20px;
}

.btn-form:hover{
    transform: scale(1.1);
    transform: translateY(-5px);
}

@media screen and (min-width:768px) {
    
    .contact-section{
        width: 90%;
        margin-top: 10% !important;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 0.5fr 1fr ;
        justify-content: center;
    }

    .row-form{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
        gap: 1rem;
    }

    .row-3{
        align-items: flex-end;
    }

    .row-4{
        grid-template-columns: 1fr;
    }

    .btn-form{
        font-size: 1.2rem;
    }

    p.form-heading {
        /* text-align: center; */
        /* width: 100%; */
        display: inline-block;
        font-size: 2rem;
        padding: 0.5rem 0;
        text-align: center;
        color: #f14d5d;
        border-bottom: #f14d5d dashed;
        margin: 1.5rem 0;
    }

    .info-heading {
        font-size: 1.8rem;
        font-weight: 600;
        align-self: flex-start;
    }

    .contact-form{
        width: 100%;
        justify-self: flex-end;
    }

    p.info-p{
        font-size: 1.2rem;
    }

}