.privacy-box{
    width: 90%;
    margin: 8% auto;
    text-align: justify;

}

.privacy-arrow-icon{
    
    font-weight: 900;
}

p.privacy-text{
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
    p.privacy-text{
        font-size: 1.5rem;
        /* margin-bottom: 2rem; */
    }
}