.terms-box{
    width: 90%;
    margin: 10% auto;
}

.terms-box ol li{
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
    .terms-box ol li{
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
    }
}