.boost-info-boxes {
    margin: 2rem auto;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    /* margin: 10% auto; */
    gap: 1.8rem;
    justify-items: center;
    overflow-x: hidden;
}

.boost-info-box {
    display: grid;
    grid-template-rows: 0.2fr 0.4fr 0.6fr;
    width: 80%;
    height: auto;
    justify-items: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 2%;
}

.boost-info-box:hover {
    transform: translateY(-5px);
}

.boost-info-box img {
    width: 50%;

}

.boy-icon {
    width: 30%;
    height: 70%;
}

.boost-intro {
    margin: 5rem 1.5rem;
    display: grid;
    grid-template-rows: 1fr 0.4fr;
    justify-items: center;
    align-items: center;
    gap: 2rem;
}

.boost-text-section {
    display: grid;
    gap: 2rem;
}

.boost-text-section h2 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}

.black-text {
    color: #000;
}

.boost-text-section h3 {
    color: #285fac;

}

.boost-text-section button {
    background-color: #285fac;
    padding: 0.4rem 0.6rem;
    border-radius: 20px;
    width: 50%;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
}

.boost-text-section button:hover{
    transform: scale(1.04);
}

.boost-logo-section {
    width: 80%;
}

.boost-logo-section img {
    width: 100%;
}

.tab-know-boost{
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.tab-bg{
    border-color: #000;
    
}

.tab-compo{
    width: 90%;
    margin: 2rem auto;
}


.tab{
    padding: 2%;
    /* color: #fff; */
}

.tab h2{
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.tab h3{
    font-size: 1.4rem;
}

.tab p{
    font-size: 1rem;
}

.know-boost{
    background-color: #285fac;
    color: #fff;
    padding: 2rem;
    height: 40%;
    border-radius: 20px;
}



.know-btn{
    background-color: #fff;
    border-radius: 20px;
    padding: 0.5rem 2rem;
}

.know-btn:hover{
    transform: scale(1.08);
}

@media screen and (min-width:768px) {
    .boost-info-boxes {
        margin: 5rem auto;
        display: grid;
        grid-template-rows: unset;
        grid-template-columns: repeat(3, 1fr);
        /* margin: 10% auto; */
        gap: 1.8rem;
        justify-items: center;
    }

    .boost-info-box {
        padding: 2.5rem;
    }

    .boost-info-box p {
        font-size: 1.1rem;

    }

    .boost-intro {
        margin: 5rem auto;
        width: 80dvw;
        display: grid;
        grid-template-rows: unset;
        grid-template-columns: 2fr 1fr;
        justify-items: center;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .boost-text-section h2 {
        color: #000;
        font-size: 4rem;
        font-weight: 600;
    }


    .boost-text-section h3 {
        color: #285fac;
        font-size: 2rem;
    }

    .boost-text-section p {
        /* color: #285fac; */
        font-size: 1.5rem;
    }

    .boost-text-section button {
        background-color: #285fac;
        padding: 0.8rem 0.6rem;
        border-radius: 20px;
        width: 50%;
    }

    .tab-know-boost{
        display: flex;
        flex-direction: row;
        padding: 2rem;
    }

    .tab-compo{
        width: 50%;
        margin: 2rem auto;
    }

    .know-boost{
        width: 30%;
        background-color: #285fac;
        color: #fff;
        padding: 2rem;
    }
}