

.heading {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin: 1rem;
    
}

.date-picker{
    width: 100%;
    height: 100%;
    padding: 8px 32px 16.5px 14px;
}

.box-form{
    margin: 1rem 0;
}

p.form-reg-heading {
    font-size: 1.5rem;
    font-weight: 900;
    color: #285fac;
}

.spec-form{
    display: grid;
    grid-template-rows: repeat(17, 1fr);
    gap: 1rem !important;
    margin: 0 0.8rem;
}

.btn-regform{
    justify-self: center;
    width: 50%;
    padding: 1rem 2rem;
}
.confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #fff;
    padding: 1rem;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 300px;
    margin: 0 1rem;
}

.popup-content p {
    font-size: 1rem;
    margin-bottom: 0.8rem;
}

.popup-content button {
    background: #285fac;
    color: #fff;
    border: none;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.popup-content button:hover {
    background: #003b8b;
}

/* .addressInput{
    grid-row-start: 10;
    grid-row-end: 12 span;
} */

@media screen and (min-width: 768px) {


    .transparent p{
        font-size: 3.4rem;
        font-weight: 700;
    }

    .boxform{
        width: 70%;
        margin: 0 auto;
    }

    p.form-reg-heading {
        font-size: 2.5rem;
        font-weight: 800;
    }



    .spec-form{
        /* display: grid; */
        grid-template-rows: repeat(7, 1fr);
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem !important;
        margin: 0 0.8rem;
    }

    .full-width-course {
        grid-column-start: 1;
        grid-column-end: 2 span;
    }
    
    .addressInput{
        grid-column-start: 1;
        grid-column-end: 2 span;
    }
    /* .email{
        grid-column-start: 1;
        grid-column-end: 2 span;
    } */

    #outlined-required{
        border-color: aquamarine;
    }

    .btn-regform{
        font-size: 1.2rem;
        font-weight: 500;
        background-color: #285fac;
        grid-column-start: 1;
        grid-column-end: 2 span;
    }
    
    .popup-content {
        padding: 2rem;
        border-radius: 8px;
        width: 90%;
        max-width: 400px;
    }

    .popup-content p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .popup-content button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
}