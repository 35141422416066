.about {

    display: grid;
    grid-template-rows: 0.5fr 3fr;
    padding: 0.5rem;
    width: 100%;
    margin: 8% 0.5rem;

    /* height: 80%; */
    /* gap: 0.5rem; */
}

.about-content {
    /* padding: 0.5rem; */
    width: 95%;
    overflow-x: hidden;
    height: auto;
}

.about-image{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: grid;
    align-items: center;
}

.about-image iframe {
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}

.section-heading p {
    font-size: 200%;
    font-weight: 500;
    padding-bottom: 0.5rem;
    display: inline-block;
    color: #f14d5d;
    border-bottom: #f14d5d dashed;
    margin: 1.5rem 0;
    /* font-style: none;
    font-size: 1rem; */
}

p.display-4 {
    color: #120f2d;
    font-size: 1.5rem;
    font-family: sans-serif;
    font-weight: 700;
    margin: 0 0 0.5rem;
    text-transform: capitalize;
    word-spacing: 0.2rem;
    word-wrap: break-word;
}

p.display-3 {
    color: #120f2d;
    font-size: 1.5rem;
    word-wrap: break-word;
    font-family: sans-serif;
    text-transform: capitalize;
    word-spacing: 0.1rem;
    font-weight: 700;
    margin: 0 0 0.5rem;
}

p.about-msg {
    text-align: justify;
    word-wrap: break-all;
    table-layout: fixed;
    line-height: 1.3;
    margin: 0.5rem 0;
}

.boxes {
    width: 100%;
    /* height: 10%; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.box {
    padding: 10%;
    text-align: center;
}

.box-msg {
    text-transform: uppercase;
    word-wrap: break-word;
}

p.counter {
    font-size: 1.7rem;
    color: #fff;
    font-weight: 500;
    text-align: center;
}

p.box-msg {
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    text-align: center;
}

figcaption {
    font-size: 1.4rem;
    text-align: center;
}

.first-box {
    background-color: #28a745;

}

.second-box {
    background-color: #4b0082;

}

.third-box {
    background-color: #f14d5d;

}

.fourth-box {
    background-color: #ffc107;

}

@media screen and (min-width: 768px) {
    .about {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-template-rows: 1fr;
        padding: 0.5rem;
        width: 100%;
        margin: 0.5rem;
        justify-items: center;
        align-items: center;
        overflow-x: hidden;
        height: 80vh;
    }

    figcaption {
        font-size: 2rem;
        text-align: center;
    }


    .about-image iframe {
        /* align-items: center; */
        justify-items: center;
        width: 100%;
        height: 60%;
        align-self: center;
    }

    .about-content {
        width: 80%;
    }

    p.about-msg {
        text-align: justify;
        word-wrap: break-all;
        table-layout: fixed;
        line-height: 1.7;
        font-size: 120%;

    }

    .boxes {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
    }

    /* .box{
        padding: 0.5rem 0.5rem;
    } */
}

@media screen and (min-width: 1024px) {
    .about {
        height: 100%;
        padding: 0.5rem;
    }


    p.counter {
        font-size: 1.5rem;
        color: #fff;
        font-weight: 800;
    }

    p.box-msg {
        font-size: 150%;
        color: #fff;
        font-weight: 500;
        text-align: center;
    }

    p.about-msg {
        /* text-align: justify;
        word-wrap: break-all;
        table-layout: fixed;
        line-height: 1.7; */
        font-size: 150%;
        line-height: 1.7;
    }

}

/*------------------------------------------------------------------------------------------------*/

.about-dir {
    display: grid;
    grid-template-rows: 1fr 0.6fr;
    padding: 2%;
    margin: 0.5rem 0.5rem;

    /* opacity: 0.3; */
    background-color: rgba(#fff, #fff, #fff, 0.3);
    /* height: auto; */
}

.logo1 img {
    width: 100%;
    height: 100%;
    /* margin: auto; */
}

p.about-section-heading {
    text-transform: capitalize;
    color: #000;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
}

.msg {
    font-size: 1rem;
    line-height: 2.0;
    margin-bottom: 1.2rem;
    text-align: justify;
}

.dir-msg {
    height: auto;
}

.dir-name {
    font-size: 1.5rem;
    color: #3e70b5;
}

.logo1 {
    display: grid;
    justify-items: center;
    align-items: flex-start;
}


@media screen and (min-width: 768px) {

    .about-dir {
        /* height: 80vh; */
        width: 85vw;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
        /* grid-template-rows: 0; */
        margin: 0 auto;
        /* margin-bottom: 10%; */
        gap: 10rem;
    }

    p.about-section-heading {
        font-size: 4rem;
        font-weight: 700;

    }

    .dir-msg {
        justify-self: flex-end;
        align-items: center;
    }

    .logo1 {
        /* justify-self: end; */
        margin: auto 0;
        display: grid;
        justify-items: center;
        /* align-items: flex-start; */
    }

    .logo1 img {
        width: 100%;
        height: 100%;
        justify-self: center;
    }

    .msg {
        font-size: 120%;
    }

    .dir-name {
        font-size: 2rem;
        color: #3e70b5;
        font-weight: 600;
    }
}

@media screen and (min-width: 1024px) {

    .about-dir {
        margin-bottom: 5%;
    }

    .msg {
        font-size: 150%;
    }
}