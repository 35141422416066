/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
}

h2 {
    color: #1d3557;
}

p {
    margin-bottom: 20px;
}

strong {
    font-weight: bold;
}

/* Simplified Pageheader */
.Pageheader {
    padding: 10px 20px;
    text-align: center;
    background-color: #ffffff;
}

.Pageheader p {
    font-size: 16px;
    color: #555;
}

/* Content Section */
.content {
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
}

.strategy {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.strategy h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.conclusion {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* Responsive Design */
@media (max-width: 768px) {
    .Pageheader p {
        font-size: 14px;
    }

    .strategy h2 {
        font-size: 22px;
    }

    .content {
        padding: 0 10px;
    }

    
}

@media (max-width: 480px) {
    .Pageheader p {
        font-size: 12px;
    }

    .strategy h2 {
        font-size: 20px;
    }

  
}
