.course-desc {
    width: 100%;
    padding: 5%;
    display: flex;
    margin: 2rem 0;
    flex-direction: column-reverse;
}

.apply-btn {
    background-color: #285fac;
    color: #fff;
    font-size: 1.4rem;
    border-radius: 20px;
    padding: 0.6rem 1rem;
    border-color: #fff;
}

.apply-btn:hover {
    transform: scale(1.1);
}

.div-img-box {
    width: 90%;
    margin: 2rem auto;
}

.div-img-box img {
    width: 100%;

}

@media screen and (min-width: 768px) {
    .course-desc {
        width: 100%;
        padding: 2% 5%;
        margin: 2rem 0;
        flex-direction: row;
        grid-gap: 5%;
    }

    .duration-eligble {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    p {
        font-size: 1.4rem;
        text-align: justify;
    }

    .course-desc-box {
        width: 100%;
    }

    .div-img-box {
        width: 90%;
        margin: 0 auto;
    }
    
    .div-img-box img {
        width: 100%;
    
    }
}