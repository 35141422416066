.courses{
    margin: 2% 0.5rem;
    /* margin-top: 100px; */
    width: 100vw;
}

.heading{
    display: grid;
    justify-items: center;
    text-align: center;
    margin: 2rem auto;
}

p.course-heading{
    display: inline-block;
    font-size: 2.5rem;
    padding: 0.5rem 0;
    text-align: center;
    color: #f14d5d;
    border-bottom: #f14d5d dashed;
    margin: 1.5rem 0;
}

.course-subheading{
    padding: 0.8rem;
}
p.course-subheading{
    display: inline-block;
    text-align: center;
    text-transform: capitalize;
    color: #5d5d5d;
}

.course-list{
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    justify-items: center;
    gap: 2rem;
}

.course-box{
    padding: .5rem;
    width: 80vw;
    cursor: pointer;

    /* height: 200px; */
}

/* .course-box:hover{
    transform: scale(1.1);
} */

.course-box img{
    width: 100%;
}

.course-box img:hover{
    cursor: pointer;
    width: 100%;
    transform: scale(1.1);
}

p.courseName{
    margin-top: 1rem;
    text-align: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #000;
    font-weight: 400;
}

@media screen and (min-width: 768px) {
    .course-list{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: unset;
        justify-items: center;
        align-items: center;
        gap: 0.5rem;
        margin: 0.5rem auto;
        justify-content: center;
        padding: 0 0.5rem;
        /* width: 90vw; */
    }
    .courses{
        margin-top: 5%;
    }

    .course-box{
        padding: 0.5rem;
        width: 80%;
        /* height: 200px; */
    }

    p.course-subheading{
        display: inline-block;
        text-align: center;
        text-transform: capitalize;
        color: #5d5d5d;
        font-size: 150%;
    }
}