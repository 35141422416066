/* Container for the slider */
.news-slider-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(45deg, #1d3557, #ffbb33);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

/* Fixed heading */
.latest-update {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: white;
}

/* Wrapper to contain sliding text */
.news-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

/* Sliding text container */
.news-list {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* Full width */
}

/* Individual news item */
.news-item {
  flex: 0 0 100%; /* Show 1 item at a time */
  padding: 20px;
  font-size: 20px;
  color: white;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for news item */
.news-item:hover {
  transform: scale(1.05);
  box-shadow: 0 18px 36px rgba(0, 0, 0, 0.3);
}

/* Navigation buttons */
.nav-button {
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .latest-update {
    font-size: 20px;
  }

  .news-item {
    font-size: 18px;
  }

  .nav-button{
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .latest-update {
    font-size: 18px;
  }

  .news-item {
    font-size: 16px;
  }

  .nav-button{
    font-size: 12px;
  }
}
