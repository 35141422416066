.float {
    position: fixed;
    width: 80px;
    height: 80px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    /* color: #FFF; */
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    /* margin-top: 16px; */
    color: #fff;

    /* background-color: #FFF; */
}

.my-float:hover {
    /* margin-top: 16px; */
    color: #fff;
    transform: scale(1.1);
    /* background-color: #FFF; */
}